// extracted by mini-css-extract-plugin
export var businessLogo = "case-study-modal-module--businessLogo--0adb0";
export var carousel = "case-study-modal-module--carousel--d3666";
export var carouselWrapper = "case-study-modal-module--carouselWrapper--69620";
export var carouselcontrol = "case-study-modal-module--carouselcontrol--c8de6";
export var caseStudiesMedia = "case-study-modal-module--caseStudiesMedia--63299";
export var caseStudiespopup = "case-study-modal-module--caseStudiespopup--eb07b";
export var closebtn = "case-study-modal-module--closebtn--74a7d";
export var content = "case-study-modal-module--content--15726";
export var contentWrapper = "case-study-modal-module--contentWrapper--e0c66";
export var description = "case-study-modal-module--description--8a9d2";
export var followerComments = "case-study-modal-module--followerComments--58561";
export var followersTitle = "case-study-modal-module--followersTitle--a1069";
export var followerstitle = "case-study-modal-module--followerstitle--ffd99";
export var gutterRows = "case-study-modal-module--gutterRows--255fa";
export var gutters = "case-study-modal-module--gutters--c1904";
export var heading = "case-study-modal-module--heading--3ed94";
export var item = "case-study-modal-module--item--c1c19";
export var media = "case-study-modal-module--media--a39d5";
export var modalBody = "case-study-modal-module--modal-body--7f96f";
export var modalContent = "case-study-modal-module--modal-content--bf2c2";
export var modalDialog = "case-study-modal-module--modal-dialog--61cf9";
export var modalHeader = "case-study-modal-module--modal-header--14cc3";
export var requestDemo = "case-study-modal-module--requestDemo--4d171";
export var videoPlayerStyle = "case-study-modal-module--videoPlayerStyle--d00b1";
export var videoReact = "case-study-modal-module--video-react--3534d";
export var wrapper = "case-study-modal-module--wrapper--5b5d4";