import React from "react";
import PropTypes from "prop-types";
import * as styles from "./Button.module.scss";

const Button = (props) => {
  const { className, style, onClick, children, white, alternate } = props;
  const classes = [styles.mainButton];

  switch (true) {
  case white:
    classes.push(styles.white);
    if (alternate) {
      classes.push(styles.whiteAlternate);
    }
    break;
  default:
    classes.push(styles.blue);
    if (alternate) {
      classes.push(styles.blueAlternate);
    }
    break;
  }

  if (className) {
    classes.push(className);
  }

  return (
    <button
      type="button"
      onClick={onClick || null}
      style={style || {}}
      className={classes.join(" ")}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  alternate: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.array).isRequired,
  white: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.object).isRequired,
};
export default Button;
