import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Text from '../../lib/NewText/Text';
import * as styles from '../../style/components/case-study-stats.module.scss';

const CaseStudyStats = (props) => {
  const [transformedStats, setStats] = useState([]);
  const breakpoints = useBreakpoint();



  useEffect(() => {
    const { language } = props;

    const transformStats = () => {
      const { data } = props;
  
      const stats = [];
  
      if (
        data.firstStatLabelEn &&
        data.firstStatLabelEs !== '' &&
        data.firstStatValue
      ) {
        stats.push({
          label:
            language === 'en' ? data.firstStatLabelEn : data.firstStatLabelEs,
          value: data.firstStatValue,
        });
      }
      if (
        data.secondStatLabelEn &&
        data.secondStatLabelEs !== '' &&
        data.secondStatValue
      ) {
        stats.push({
          label:
            language === 'en' ? data.secondStatLabelEn : data.secondStatLabelEs,
          value: data.secondStatValue,
        });
      }
      if (
        data.thirdStatLabelEn &&
        data.thirdStatLabelEs !== '' &&
        data.thirdStatValue
      ) {
        stats.push({
          label:
            language === 'en' ? data.thirdStatLabelEn : data.thirdStatLabelEs,
          value: data.thirdStatValue,
        });
      }
      if (
        data.fourthStatLabelEn &&
        data.fourthStatLabelEs !== '' &&
        data.fourthStatValue
      ) {
        stats.push({
          label:
            language === 'en' ? data.fourthStatLabelEn : data.fourthStatLabelEs,
          value: data.fourthStatValue,
        });
      }
  
      setStats(stats);
    };
    transformStats();
  }, [props]);

  const statsClasses = [
    styles.itemContents,
    breakpoints.md ? styles.smallContents : '',
  ].join(' ');

  const primaryStatsClasses = statsClasses;

  return (
    <div className={styles.item} style={{ marginTop: '20px', width: '100%' }}>
      {transformedStats.map((stat) => (
        <div key={`${stat.label}_${1}`} className={primaryStatsClasses}>
          <Text className={styles.stats} title>
            {stat.label}
          </Text>
          <Text body>{stat.value}</Text>
        </div>
      ))}
    </div>
  );
};

CaseStudyStats.propTypes = {
  language: PropTypes.string.isRequired,
  data: PropTypes.shape({
    firstStatLabelEn : PropTypes.string,
    firstStatLabelEs : PropTypes.string,
    secondStatLabelEn : PropTypes.string,
    secondStatLabelEs : PropTypes.string,
    firstStatValue : PropTypes.string,
    secondStatValue : PropTypes.string,
    thirdStatValue : PropTypes.string,
    fourthStatValue : PropTypes.string,
    followersCommentEn : PropTypes.string,
    followersCommentEs : PropTypes.string,
    thirdStatLabelEs : PropTypes.string,
    thirdStatLabelEn : PropTypes.string,
    fourthStatLabelEs : PropTypes.string,
    fourthStatLabelEn : PropTypes.string,
  }).isRequired
};

export default CaseStudyStats;
