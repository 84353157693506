import React, { useRef, useEffect, useState } from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Modal, Carousel } from 'react-bootstrap';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import PropTypes from 'prop-types';
import VideoPlayer from '../../lib/Video/VideoPlayer';

import closeImg from '../../images/close_icon.png';
import CaseStudyStats from './case-study-stats';
import Button from '../../lib/NewButton/Button';
import Text from '../../lib/NewText/Text';
import * as styles from '../../style/components/case-study-modal.module.scss';
import '../../style/ui/media-carousel.scss';

const CaseStudyModal = (props) => {
  const { language, data, onCancel } = props;
  const { caseStudies } = data;
  const [carouselInterval, setCarouselInterval] = useState(2500);
  const [activeIndex, setActiveIndex] = useState(0);
  const itemsRef = useRef([]);
  const breakpoints = useBreakpoint();

  const transformedData = caseStudies.campaignMedia.map((item, index) => ({
    key: index,
    id: item.id,
    type: item.mediaType,
    url: item.mediaItemUrl
  }));

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, data.length);
  }, [data.length]);

  const closeBtn = (
    <button
      type="button"
      className={styles.closebtn}
      onClick={() => {
        onCancel();
      }}>
      <img src={closeImg} alt="Cross" />
    </button>
  );

  const content = () => {
    const businessLogo = caseStudies.businessLogo ? (
      <div
        className={styles.businessLogo}
        style={{
          backgroundImage: `url("${caseStudies.businessLogo.sourceUrl}")`
        }}
      />
    ) : (
      <></>
    );

    const campaignTitle = (
      <Text className={styles.heading} title>
        {language === 'en' ? caseStudies.titleEn : caseStudies.titleEs}
      </Text>
    );

    const description = (
      <Text className={styles.description} body>
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html:
              language === 'es'
                ? caseStudies.campaignDescriptionEs
                : caseStudies.campaignDescriptionEn
          }}
        />
      </Text>
    );

    const { firstStatValue, secondStatValue, thirdStatValue, fourthStatValue } = caseStudies;
    let stats = null;
    if (firstStatValue || secondStatValue || thirdStatValue || fourthStatValue) {
      stats = <CaseStudyStats language={language} data={caseStudies} />;
    }

    let commentsAvailable = false;

    if (language === 'en') {
      if (caseStudies.followersCommentEn && caseStudies.followersCommentEn !== '') {
        commentsAvailable = true;
      }
    } else if (caseStudies.followersCommentEs && caseStudies.followersCommentEs !== '') {
      commentsAvailable = true;
    }

    const followersText =
      language === 'en' ? caseStudies.followersCommentEn : caseStudies.followersCommentEs;

    const followersComments = commentsAvailable && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          marginTop: '20px',
          width: '100%',
          flexFlow: 'column',
          textAlign: 'left'
        }}>
        <Text className={styles.followersTitle} title>
          <FormattedMessage id="case-studies-page.followersSaid" />
        </Text>
        <Text detail className={styles.followerComments}>
          {followersText
            .split('<br/>')
            .join('<br />')
            .split('<br />')
            .map((text) => (
              <>
                <span>{text}</span>
                <br />
              </>
            ))}
        </Text>
      </div>
    );

    return (
      <div className={styles.content}>
        {businessLogo}
        {campaignTitle}
        {description}
        {stats}
        {followersComments}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            width: '100%'
          }}>
          <Button
            className={styles.requestDemo}
            onClick={() => {
              window.open(`${process.env.GATSBY_COMPANY_URL}/request-demo?lang=${language}`);
            }}>
            <FormattedMessage id="case-studies-page.requestDemo" />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      dialogClassName={breakpoints.md && styles.caseStudiespopup}
      className={!breakpoints.md && styles.caseStudiespopup}
      show
      onHide={onCancel}>
      <div className={['row row-no-gutters', styles.gutters].join(' ')}>
        <div
          className={[
            'col-lg-6 col-md-6 col-sm-12 col-xs-12 col-full-height',
            styles.gutterRows,
            styles.gutterRow0
          ].join(' ')}>
          <div className={styles.carouselWrapper}>
            <Carousel
              className={styles.carousel}
              interval={carouselInterval}
              indicators
              onSelect={(e) => {
                const prevIdx = activeIndex;
                setActiveIndex(e);

                if (itemsRef.current[prevIdx]) {
                  itemsRef.current[prevIdx].pause();
                }
              }}>
              {transformedData.map((item, index) => (
                <Carousel.Item className={styles.item} key={item.id}>
                  {item.type === 'image' ? (
                    <img className={styles.caseStudiesMedia} src={item.url} alt="" />
                  ) : (
                    <VideoPlayer
                      style={styles.videoPlayerStyle}
                      ref={(el) => {
                        itemsRef.current[index] = el;
                      }}
                      onPlay={() => setCarouselInterval(30000)}
                      onPause={() => setCarouselInterval(2500)}>
                      <source src={item.url} />
                    </VideoPlayer>
                  )}
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
        <div
          className={[
            'col-lg-6 col-md-6 col-sm-12 col-xs-12 col-full-height',
            styles.gutterRows
          ].join(' ')}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
          <div style={{ padding: '15px' }}>{content()}</div>
        </div>
        {closeBtn}
      </div>
    </Modal>
  );
};

CaseStudyModal.propTypes = {
  language: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.shape({
    caseStudies: PropTypes.shape({
      campaignMedia: PropTypes.objectOf(
        PropTypes.shape({
          map: PropTypes.func
        })
      ),
      businessLogo: PropTypes.objectOf(
        PropTypes.shape({
          sourceUrl: PropTypes.string
        })
      ),
      titleEn: PropTypes.string,
      titleEs: PropTypes.string,
      campaignDescriptionEs: PropTypes.string,
      campaignDescriptionEn: PropTypes.string,
      firstStatValue: PropTypes.string,
      secondStatValue: PropTypes.string,
      thirdStatValue: PropTypes.string,
      fourthStatValue: PropTypes.string,
      followersCommentEn: PropTypes.string,
      followersCommentEs: PropTypes.string
    }),
    length: PropTypes.number
  }).isRequired
};

export default CaseStudyModal;
