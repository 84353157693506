import React from "react";
import { BigPlayButton, Player } from "video-react";
import PropTypes from "prop-types";

import "video-react/dist/video-react.css";
import "./video-player.scss";

const VideoPlayer = ({ children, style, onPlay, onPause }, ref) => (
  <div className={style}>
    <Player onPause={onPause} onPlay={onPlay} ref={ref}>
      <BigPlayButton position="center" />
      {children}
    </Player>
  </div>
);

VideoPlayer.propTypes = {
  children: PropTypes.string.isRequired,
  style: PropTypes.objectOf.isRequired,
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
};
export default React.forwardRef(VideoPlayer);
