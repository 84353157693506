import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { PageProps } from 'gatsby';
import CaseStudiesContent from '../components/case-studies';
import CaseStudyModal from '../components/case-studies/case-study-modal';
import SortButtonCaseStudies from '../components/case-studies/sort-button';
import Layout from '../components/ui/layout';
import Button from '../lib/NewButton/Button';
import Text from '../lib/NewText/Text';
import { fetchAllCaseStudies } from '../services/case-studies';
import DefaultLanguage from '../hoc/default-language';
import * as styles from '../style/pages/case-studies.module.scss';
import SEO from '../components/ui/seo';

const DATA_COUNT = 9;

class CaseStudies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      transformedData: [],
      showMoreCount: 1,
      sortKey: null,
      showModal: false,
      activeCaseStudy: null,
      isLoading: true
    };

    this.changeActiveData = this.changeActiveData.bind(this);
    this.sortData = this.sortData.bind(this);
    this.changeSortKey = this.changeSortKey.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    fetchAllCaseStudies()
      .then((response) => {
        if (response.data) {
          const {
            data: { caseStudies }
          } = response;

          const { nodes: allData } = caseStudies;

          this.setState({ data: allData }, () => {
            this.sortData();
            this.changeActiveData(allData);
          });
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  componentDidUpdate(_, prevState) {
    const { sortKey } = this.state;
    if (prevState.sortKey !== sortKey) {
      this.sortData();
    }
  }

  changeActiveData(newData) {
    const { data } = this.state;
    const allData = newData || data;

    const url = typeof window !== `undefined` ? new URL(window.location.href) : null;
    if (url && url.search) {
      const activeStudy = allData.find((item) => {
        const i = item.caseStudies.shareableLink === url.search.substring(1);
        return i;
      });
      if (activeStudy) {
        this.setState({ showModal: true, activeCaseStudy: activeStudy });
      }
    }
  }

  changeSortKey(key) {
    const { sortKey: oldSortKey } = this.state;
    const isSelected = oldSortKey === key;

    let newSortKey = 'rank';

    if (!isSelected) {
      newSortKey = key;
    }
    this.setState({ sortKey: newSortKey });
  }

  sortData() {
    const {
      pageContext: { language }
    } = this.props;

    const transformData = (data, key) => {
      if (key === 'modified') {
        return data.sort((a, b) => new Date(b.date) - new Date(a.date));
      }
      if (key === 'alphabetic') {
        const alpha = language === 'en' ? 'titleEn' : 'titleEs';

        return data.sort((a, b) => {
          if (a.caseStudies[alpha] < b.caseStudies[alpha]) {
            return -1;
          }
          if (a.caseStudies[alpha] > b.caseStudies[alpha]) {
            return 1;
          }
          return 0;
        });
      }
      if (key === 'rank') {
        return data.sort((a, b) => {
          if (a.caseStudies.rank < b.caseStudies.rank) {
            return -1;
          }
          if (a.caseStudies.rank > b.caseStudies.rank) {
            return 1;
          }
          return 0;
        });
      }
      return data;
    };

    const { sortKey, data } = this.state;
    const outKey = sortKey || 'rank';

    const newData = transformData(data, outKey);
    this.setState({ transformedData: newData });
  }

  toggleModal(bool, caseStudy) {
    const {
      navigate,
      pageContext: { language }
    } = this.props;
    const path = language === 'en' ? '/en/case-studies' : '/es/case-studies';

    if (bool) {
      this.setState({ showModal: bool, activeCaseStudy: caseStudy });
      navigate(`${path}/?${caseStudy.caseStudies.shareableLink}`);
    } else {
      this.setState({ showModal: bool, activeCaseStudy: null });
      navigate(path);
    }
  }

  render() {
    const { location } = this.props;

    const { transformedData, isLoading, data, showMoreCount, sortKey, showModal, activeCaseStudy } =
      this.state;

    const { pageContext } = this.props;
    const counterLength = DATA_COUNT * showMoreCount;
    const mainClasses = [styles.mainRow, 'container'].join(' ');

    return (
      <>
        <SEO page="campaigns" language={pageContext.language} />
        <DefaultLanguage location={location}>
          <Layout location={location} language={pageContext.language} page="case-studies">
            {isLoading && (
              <div
                className={mainClasses}
                style={{
                  height: '50vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <ClipLoader color="#3F5C83" loading={isLoading} width={10} size={100} />
              </div>
            )}
            {!isLoading && data.length > 0 && (
              <>
                <div className={mainClasses}>
                  <Text className={styles.mainHeading} title>
                    <FormattedMessage id="case-studies-page.recent" />
                  </Text>

                  <div className={['row', styles.cardsRow, styles.sortRow].join(' ')}>
                    <SortButtonCaseStudies sortKey={sortKey} onClick={this.changeSortKey} />
                  </div>
                  <div className={['row', styles.cardsRow].join(' ')}>
                    <CaseStudiesContent
                      language={pageContext.language}
                      data={transformedData.slice(0, counterLength)}
                      onClick={this.toggleModal}
                    />
                  </div>
                  {transformedData.length >= counterLength && (
                    <div className={['row', styles.showMoreRow].join(' ')}>
                      <Button
                        onClick={() => {
                          this.setState((prevState) => ({
                            showMoreCount: prevState.showMoreCount + 1
                          }));
                        }}>
                        <FormattedMessage id="common.buttons.showMore" />
                      </Button>
                    </div>
                  )}
                </div>

                {showModal && (
                  <CaseStudyModal
                    language={pageContext.language}
                    showModal={showModal}
                    data={activeCaseStudy}
                    onCancel={() => {
                      this.toggleModal(false);
                    }}
                  />
                )}
              </>
            )}
            {!isLoading && data.length <= 0 && (
              <div className={mainClasses} style={{ height: '50vh' }}>
                <Text title>No Data Found</Text>
              </div>
            )}
          </Layout>
        </DefaultLanguage>
      </>
    );
  }
}

CaseStudies.propTypes = {
  pageContext: PropTypes.instanceOf(PageProps).isRequired,
  location: PropTypes.instanceOf(PageProps).isRequired,
  navigate: PropTypes.func.isRequired
};

export default injectIntl(CaseStudies);
