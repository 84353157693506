import React from 'react';
import PropTypes from 'prop-types';

import numuLogo from '../../images/numu-icon.png';
import Text from '../../lib/NewText/Text';
import * as styles from '../../style/components/case-studies.module.scss';
import CaseStudyCard from '../ui/case-study-card';

const CaseStudies = (props) => {
  const { language, data, onClick } = props;

  const renderCards = () =>
    data.map((caseStudy, i) => {
      const { id, caseStudies } = caseStudy;
      const backgroundImage = caseStudies.campaignCardBackground.sourceUrl;
      const hoverContent = (
        <Text
          title
          style={{ fontSize: '1.5rem', wordBreak: 'break-all' }}
          className={styles.uppercase}
        >
          {language === 'en' ? caseStudies.titleEn : caseStudies.titleEs}
        </Text>
      );

      const key = `${id}_${Math.random(i) * i}`;
      return (
        <div key={key} className='col-sm-6 col-md-4 col-lg-4'>
          <CaseStudyCard
            onClick={() => {
              onClick(true, caseStudy);
            }}
            id={parseInt(i, 10)}
            backgroundImage={backgroundImage}
            hoverContent={hoverContent}
            businessName={caseStudies.businessName}
            businessLogo={
              caseStudies.businessLogo
                ? caseStudies.businessLogo.sourceUrl
                : numuLogo
            }
          />
        </div>
      );
    });

  return <>{renderCards()}</>;
};

CaseStudies.propTypes = {
  language: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  data: PropTypes.shape({
    map: PropTypes.func
  }).isRequired
};

export default CaseStudies;
