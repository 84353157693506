import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Text from '../../lib/NewText/Text';
import * as styles from '../../style/components/sort-button.module.scss';

const SortButton = (props) => {
  const { sortKey, onClick } = props;

  const getDropdownItemClasses = (check) =>
    `${styles.dropdownItem} ${check === true ? styles.selected : ''}`;

  const renderSelectedIcon = (check) =>
    check === true ? <span>&#10003;</span> : null;

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          id='dropdown-basic'
          className={[styles.is, styles.toggle].join(' ')}
        >
          <Text subtitle style={{ fontSize: '1rem' }}>
            <FormattedMessage id='case-studies-page.sortBy' />
            &nbsp;
          </Text>
          <StaticImage
            placeholder='blurred'
            backgroundColor='transparent'
            className={styles.caret}
            width={12}
            height={12}
            src='../../images/down-arrow.png'
            alt='CoolBrand'
          />
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles.dropdown} style={{ width: '100%' }}>
          <Dropdown.Item
            className={getDropdownItemClasses(!!sortKey)}
            onClick={(e) => {
              e.preventDefault();
              onClick('modified');
            }}
          >
            <Text subtitle>
              <FormattedMessage id='common.text.latest' />
            </Text>
            {renderSelectedIcon(sortKey === 'modified')}
          </Dropdown.Item>
          <Dropdown.Item
            className={getDropdownItemClasses(!!sortKey)}
            onClick={(e) => {
              e.preventDefault();
              onClick('alphabetic');
            }}
          >
            <Text subtitle>A to Z</Text>
            {renderSelectedIcon(sortKey === 'alphabetic')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

SortButton.propTypes = {
  sortKey: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SortButton;
